<template>
  <div>
    <page-main back title class="scene-layout">
      <template slot="top">
        <Steps
          :value="1"
          :list="[
            `1.新建拜访`,
            '2.设置任务规则',
            '3.选择任务对象并分配',
          ]"
        />
      </template>
      <el-form
        label-width="140px"
        label-suffix="："
        size="small"
        class="customize-form cs-in-bold-title"
        style="width: 900px; margin-top: 30px;"
      >
        <el-form-item required label="拜访标题">
          <el-input
            v-model="title"
            class="scene-form-input title-input"
            placeholder="请输入拜访标题，标题不能包含产品名称和组织名称"
            maxlength="40"
            show-word-limit
          />
        </el-form-item>
        <el-form-item required label="任务提示">
          <el-input
            v-model="intro"
            class="scene-form-input"
            type="textarea"
            placeholder="请输入任务提示，该提示仅对代表可见"
            rows="8"
            maxlength="400"
            show-word-limit
          />
        </el-form-item>
        <el-form-item required label="标签选择">
          <el-row align="middle">
            <el-tag
              v-for="v in selectedTagList"
              :key="v.tagId"
              class="custom-tag"
              :closable="!v.disabled"
              @close="onDelTag(v.tagId)"
            >
              {{ v.tagName }}
            </el-tag>
            <el-button plain type="info" @click="onOpenTagModal">
              添加标签<svg-icon name="el-icon-plus" />
            </el-button>
          </el-row>
        </el-form-item>
        <el-form-item required label="拜访内容库">
          <el-button plain type="info" @click="onOpenMaterialPop">
            添加素材<svg-icon name="el-icon-plus" />
          </el-button>
          <span class="colc8c9ce mgn-l20">
            <span>备注：可添加视频、文献、题目、问卷。代表进行单次拜访任务的时候，只可选择一种类型进行线上拜访。视频/文献单选，
              题目/问卷支持多选。</span>
            <span
              class="text-primary-link"
              @click="onMoreDoubt"
            >更多疑问，点击这里</span>
          </span>
          <div style="margin-top: 10px;">
            <el-table :data="materialList" borderd>
              <el-table-column label="标题/内容" show-overflow-tooltip fixed>
                <template slot-scope="scope">
                  <span>{{ scope.row.title | textFilter
                  }}{{ scope.row.examTypeEnum | examTypeFilter }}</span>
                </template>
              </el-table-column>
              <el-table-column label="类型" width="200" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.contentType || "—" }}</span>
                </template>
              </el-table-column>
              <el-table-column label="任务完成条件" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.contentType | visitFinishFilter }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                width="200"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span
                    class="text-primary-link"
                    @click="onDelMaterial(scope.row)"
                  >删除</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form-item>
      </el-form>
      <div
        v-show="isShowToast"
        class="toast-box"
        :class="{ errorBg: isErrorBg }"
      >
        {{ toastMessage }}
      </div>
    </page-main>
    <fixed-action-bar>
      <el-button
        plain
        style="min-width: 90px;"
        @click="onCancelPop"
      >
        取消
      </el-button>
      <el-button
        type="primary"
        style="min-width: 90px;"
        :loading="isSubmit"
        @click="onSaveData"
      >
        保存
      </el-button>
      <el-button
        type="primary"
        :loading="isSubmit"
        style="min-width: 90px;"
        @click="onSaveData(true)"
      >
        下一步
      </el-button>
    </fixed-action-bar>
    <div class="footer-box" />
    <SelectSceneTagModal
      :visible="showTagModal"
      :target-list="selectedTagList"
      @onClose="onClosePop"
      @onSubmit="onSubmitTag"
    />
    <MaterialSelectModal
      :visible.sync="isShowMaterialModal"
      :article-list="articleList"
      :exam-list="examList"
      :video-list="videoList"
      :show-exam="false"
      :show-question="false"
      :question-list="questionList"
      @onClose="onClosePop"
      @onSubmit="onSubmitMaterial"
    />
    <OperationConfirmPop
      :visible="isShowOperationConfirmPop"
      :message="message"
      title="操作确认"
      cancel-font="继续编辑"
      confirm-font="确定离开"
      @onClose="onClosePop"
      @onSubmit="onSubmitPop"
    />
  </div>
</template>

<script>
import OperationConfirmPop from '@/bizComponents/OperationConfirmPop'
import SelectSceneTagModal from '@/bizComponents/SelectSceneTagModal'
import MaterialSelectModal from './child/MaterialSelectModal.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'EditVisit',
  components: {
    SelectSceneTagModal,
    OperationConfirmPop,
    MaterialSelectModal
  },
  filters: {
    visitFinishFilter(type) {
      switch (type) {
        case '视频':
          return '打开视频'
        case '文献':
          return '打开文献'
        case '题目':
        case '问卷':
          return '完成答题'
      }
    },
    textFilter(text) {
      return text.replace(/<filter><\/filter>/g, ' ______ ')
    },
    examTypeFilter(type) {
      switch (type) {
        case 'single':
          return '（单选题）'
        case 'multiple':
          return '（多选题）'
        case 'text_fill':
          return '（问答题）'
        case 'blank_fill':
          return '（填空题）'
        case 'sort':
          return '（排序题）'
        case 'scale':
          return '（量表题）'
      }
    }
  },
  data() {
    return {
      title: '',
      intro: '',
      userTagType: 11, // 标签分类
      articleList: [],
      questionList: [],
      isAttribute: false,
      sceneType: 'academicVisit',
      videoList: [],
      canEdit: true,
      sceneId: '',
      examList: [],
      selectedTagList: [],
      isShowToast: false,
      toastMessage: '',
      isErrorBg: false,
      isSaveConfirm: false,
      // ------------------------------ 上传视频弹窗 ----------------------
      showTagModal: false,
      // ------------------- 视频预览 ------------------

      // ---------------------- 弹窗 ------------------------
      isShowMaterialModal: false,
      isShowOperationConfirmPop: false,
      isSubmit: false,
      // ----------------------------- questionPreview 问题预览 ---------------------
      // -------------------------- operationConfirmPop 离开确认--------------------
      message: '当前页面未编辑完成，您确定要离开当前编辑页面吗？'
    }
  },
  computed: {
    ...mapGetters(['tagGroupList']),
    materialList() {
      return [
        ...this.videoList,
        ...this.articleList,
        ...this.examList,
        ...this.questionList
      ]
    }
  },
  watch: {
    isShowToast(isShow) {
      if (isShow) {
        setTimeout(() => {
          this.isShowToast = false
        }, 3000)
      }
    }
  },
  mounted() {
    this.initPage()
    this.getTagGroup()
  },
  methods: {
    ...mapActions([
      'onEditAcademicVisit',
      'getAcademicVisitDetail',
      'getSceneDetailTag',
      'getTagGroup'
    ]),
    onMoreDoubt() {
      // TODO: 缺少链接
      window.open('/help/detail/6c342ac8f983c33fb95828fcf2a61a94')
    },
    formatTableData() {
      // 视频
      const output = []
      this.videoList.map(item => {
        const { videoId, title, key } = item
        output.push({
          key: videoId || key,
          title,
          contentType: 0,
          conditions: '打开视频',
          contentId: videoId
        })
      })
      // 文献
      this.articleList.map(item => {
        const { knowledgeId, title, knowledgeType, key } = item
        output.push({
          key: knowledgeId || key,
          title,
          contentType: 2,
          knowledgeType,
          conditions: '打开文献',
          contentId: knowledgeId
        })
      })
      // 题目
      this.examList.map(item => {
        const { examId, title, key } = item
        output.push({
          key: examId || key,
          title,
          contentType: 1,
          conditions: '完成答题',
          contentId: examId
        })
      })
      this.questionList.map(item => {
        const { examId, title, key } = item
        output.push({
          key: examId || key,
          title,
          contentType: 3,
          conditions: '完成答题',
          contentId: examId
        })
      })
      return output
    },
    initPage() {
      this.sceneId = this.$route.params.id
      this.loading = true
      if (!this.sceneId) return
      this.getAcademicVisitDetail(this.sceneId).then(
        res => {
          this.loading = false
          this.title = res.title
          this.canEdit = res.canEdit
          this.isAttribute = res.isAttribute
          this.intro = res.intro
          res.contentInfos.map(item => {
            const { contentType, visitContentId, contentTitle } = item
            item.title = contentTitle
            if (contentType === '视频') {
              item.videoId = visitContentId
              this.videoList.push(item)
            } else if (contentType === '文献') {
              item.knowledgeId = visitContentId
              this.articleList.push(item)
            } else if (contentType === '题目') {
              item.examId = visitContentId
              item.type = 0
              this.examList.push(item)
            } else if (contentType === '问卷') {
              item.examId = visitContentId
              item.type = 1
              this.questionList.push(item)
            }
          })
          this.initTag()
        },
        () => {
          this.loading = false
        }
      )
    },
    initTag() {
      const groupIds = this.tagGroupList.map(v => v.id)
      const req = {
        memberIdList: [this.sceneId],
        userTagType: this.userTagType,
        tagType: 1,
        groupIds: groupIds
      }
      this.getSceneDetailTag(req).then(res => {
        this.selectedTagList = [...res].map(v => {
          return { ...v, disabled: true }
        })
      })
    },
    onCancelPop() {
      this.onOpenOperationConfirmPop()
    },
    goBack() {
      this.$router.go(-1)
    },
    onSubmitTag(newSelectList, selectedList) {
      this.showTagModal = false
      this.selectedTagList = [...selectedList, ...newSelectList]
    },
    onClosePop() {
      this.showTagModal = false
      this.isShowMaterialModal = false
      this.isShowOperationConfirmPop = false
    },
    onSubmitMaterial({ examList, articleList, videoList, questionList }) {
      this.examList = examList.map(v => {
        return { ...v, contentType: '题目' }
      })
      this.questionList = questionList.map(v => {
        return { ...v, contentType: '问卷' }
      })
      this.articleList = articleList.map(v => {
        return { ...v, contentType: '文献' }
      })
      this.videoList = videoList.map(v => {
        return { ...v, contentType: '视频' }
      })
      this.isShowMaterialModal = false
    },
    onSubmitPop() {
      this.$router.push('/academic/list')
    },
    onOpenMaterialPop() {
      this.isShowMaterialModal = true
    },
    onDelTag(currentTagId) {
      if (this.isAttribute)
        return this.$message.error('该活动已分配给医生，标签不支持删除')
      this.selectedTagList = [...this.selectedTagList].filter(
        v => v.tagId != currentTagId
      )
    },
    onOpenTagModal() {
      this.showTagModal = true
    },
    onDelMaterial(item) {
      if (this.isAttribute)
        return this.$message.error('该活动已分配给医生，素材库内容不支持删除')
      switch (item.contentType) {
        case '视频':
          this.videoList = [...this.videoList].filter(
            v => v.videoId !== item.videoId
          )
          break
        case '题目':
          this.examList = [...this.examList].filter(
            v => v.examId !== item.examId
          )
          break
        case '问卷':
          this.questionList = [...this.questionList].filter(
            v => v.examId !== item.examId
          )
          break
        case '文献':
          this.articleList = [...this.articleList].filter(
            v => v.knowledgeId !== item.knowledgeId
          )
          break
      }
    },
    onOpenOperationConfirmPop() {
      if (!this.isSaveConfirm) {
        this.isShowOperationConfirmPop = true
      } else {
        this.goBack()
      }
    },
    onValid() {
      if (!this.title) {
        this.isShowToast = true
        this.toastMessage = '请填写拜访标题'
        this.isErrorBg = true
        return false
      }
      if (!this.intro) {
        this.isShowToast = true
        this.toastMessage = '请填写任务提示'
        this.isErrorBg = true
        return false
      }
      if (this.onCheckReq()) {
        this.isShowToast = true
        this.toastMessage = '请选择产品标签'
        this.isErrorBg = true
        return false
      }
      if (this.materialList.length === 0) {
        this.isShowToast = true
        this.toastMessage = '请添加素材内容'
        this.isErrorBg = true
        return false
      }
      return true
    },
    onCheckReq() {
      for (const item of this.selectedTagList) {
        if (this.tagGroupList[0].id === item.groupId) {
          return false
        }
      }
      return true
    },
    onNext() {
      this.$router.push(
        `/academic/scene/${this.sceneType}/rule/${this.sceneId}`
      )
    },
    onSaveData(isNext) {
      if (!this.onValid() || this.isSubmit) return
      const memberTagInfo = []
      this.selectedTagList.map(v => {
        memberTagInfo.push({
          userTagType: this.userTagType,
          tagId: v.tagId,
          groupId: v.groupId,
          tagName: v.tagName,
          memberId: this.sceneId
        })
      })
      const req = {
        visitTitle: this.title,
        intro: this.intro,
        visitContentBos: this.formatTableData(),
        memberTagInfo: memberTagInfo,
        isAttribute: this.isAttribute
      }
      if (this.sceneId) {
        req['id'] = this.sceneId
      }
      this.loading = true
      this.onEditAcademicVisit(req).then(
        res => {
          this.isShowToast = true
          this.toastMessage = '保存成功'
          this.isErrorBg = false
          this.sceneId = res.id
          this.isSaveConfirm = false
          this.isSubmit = false
          if (isNext === true) {
            this.onNext()
            return
          }
          setTimeout(() => {
            this.goBack()
          }, 1000)
        },
        rea => {
          this.isSubmit = false
          this.isShowToast = true
          this.toastMessage = rea
          this.isErrorBg = true
          this.isSaveConfirm = false
        }
      )
    }
  }
}
</script>

<style lang='scss' scoped>
.custom-tag {
  margin-right: 10px;
}
.toast-box {
  color: #fff;
  background: green;
}
.errorBg {
  background: #d8384a;
}
</style>
